<template>
  <section>
    <b-loading :active="isLoading" :is-full-page="false"></b-loading>

    <b-modal
      :active.sync="showFilterModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <TableColumnFilter
          :columns="visibleFiltersModalColumn"
          :data="filters"
          :draggable="false"
          @toggleVisibility="onFiltersToggleVisibility"
          @toggleVisibilityAll="onFiltersToggleVisibility"
        >
          <template #header>
            <p class="modal-card-title is-size-5">
              {{
                isMobile
                  ? $t("table.col-options.mobile.header")
                  : $t("table.col-options.header")
              }}
            </p>
          </template>
          <template #beforeTable>
            <p class="is-size-5">
              {{
                isMobile
                  ? $t("table.col-options.mobile.col-filter-title")
                  : $t("table.col-options.col-filter-title")
              }}
            </p>
          </template>
        </TableColumnFilter>
      </template>
    </b-modal>

    <!-- property filters -->
    <section class="section has-background-eurotext-light">
      <div class="container">
        <button class="button is-primary block" @click="showFilterModal = true">
          {{ $t("buttons.hide_search_filters") }}
        </button>

        <form
          @submit.prevent="fetchInvoices"
          @reset.prevent="resetForm"
          class="search-filters block"
        >
          <div class="columns is-multiline">
            <div
              v-for="filter of visibleFilters"
              :key="filter.name"
              class="column is-one-third-tablet is-one-third-desktop is-one-quarter-widescreen is-one-fifth-fullhd custom-column-filter-align search-filter"
            >
              <b-field :label="$t(`einvoice.filters.${filter.name}`)">
                <template #label
                  ><span class="has-text-light">{{
                    $t(`einvoice.filters.${filter.name}`)
                  }}</span></template
                >

                <b-select
                  :value="filter.operator"
                  @input="changeFilterOperator($event, filter)"
                >
                  <option
                    v-for="option in valueTypes[filter.type].operators"
                    :value="option.key"
                    :key="option.key"
                    v-html="option.icon"
                  ></option>
                </b-select>
                <!-- <b-datepicker
                  v-if="filter.type === 'DATE_LEGACY'"
                  icon="calendar-today"
                  v-model="filter.filterValue.value1"
                >
                  <button
                    type="button"
                    class="button is-danger"
                    @click="filter.filterValue.value1 = null"
                  >
                    <b-icon icon="close"></b-icon></button
                ></b-datepicker> -->

                <custom-b-date-picker
                  v-if="isDate(filter.type) || isDateTime(filter.type)"
                  icon="calendar-today"
                  :range="filter.operator === operators.BETWEEN.key"
                  expanded
                  v-model="filter.value"
                  :locale="$i18n.locale"
                  :dateFormat="$store.getters.getDatePattern"
                >
                </custom-b-date-picker>

                <b-taginput
                  v-else-if="filter.operator === operators.IN.key"
                  v-model="filter.value"
                  :confirm-keys="[';', 'Enter', 'Tab']"
                  :on-paste-separators="[';']"
                  :maxtags="$config.search.max_search_tags"
                  has-counter
                  expanded
                  check-infinite-scroll
                  ellipsis
                  aria-close-label="Delete this tag"
                  class="has-text-light"
                ></b-taginput>

                <boolean-input
                    v-else-if="isBoolean(filter.type)"
                    expanded
                    v-model="filter.value"
                    style="width: 100%"
                  ></boolean-input>

                <b-input
                  v-else
                  v-model="filter.value"
                  expanded
                  size=""
                ></b-input>
              </b-field>
            </div>
            <div
              class="column is-one-third-tablet is-one-third-desktop is-one-quarter-widescreen is-one-fifth-fullhd custom-column-filter-align"
            >
              <label class="label has-text-light">
                {{ $t("einvoice.status.label") }}
              </label>
              <b-dropdown v-model="einvStatus" aria-role="list">
                <button
                  type="button"
                  class="button is-fullwidth"
                  :class="einvStatus ? `is-itside-${einvStatus.value}` : ''"
                  slot="trigger"
                  slot-scope="{ active }"
                >
                  <span>
                    {{
                      einvStatus
                        ? einvStatus.label
                        : $t("einvoice.status.select_status")
                    }}
                  </span>
                  <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                </button>
                <b-dropdown-item value=""
                  ><span>{{
                    $t("einvoice.status.select_status")
                  }}</span></b-dropdown-item
                >
                <b-dropdown-item
                  v-for="item in einvStatusOptions"
                  :key="item.value"
                  :value="item"
                  :class="`has-background-itside-${item.value}`"
                >
                  <span class="">
                    {{ item.label }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="buttons">
            <b-button
              type="is-primary"
              native-type="submit"
              icon-left="magnify"
            >
              {{ $t("buttons.search") }}
            </b-button>
            <b-button type="is-primary" native-type="reset" icon-left="eraser">
              {{ $t("buttons.erase") }}
            </b-button>
          </div>
        </form>
      </div>
    </section>

    <section class="section">
      <document-table
        :key="`itc-${invoiceTableColumns.length}`"
        :data="invoiceData"
        :tableColumns="invoiceTableColumns"
        :isLoadingRows="isLoadingInvoices"
        :total="totalTablePages"
        :perPage="tablePerPage"
        :totalRecords="totalDocumentNum"
        :downloadTypes="downloadTypes"
        :documentType="'einvoice'"
        :saveState="true"
        :stateKey="stateKey"
        :defaultOrderedColumnsNames="['_einv_color', 'status']"
        :backend-sorting="true"
        @page="onTablePage($event)"
        @row-click="openEinvoiceDetail"
        @download="downloadEInvoices"
        @download-total="downloadTotalEInvoices"
        @sort-data="onTableSort"
      >
        <template #cell="{ cellData, column, row }">
          <!-- <span
              v-if="column.field === 'status'"
              :class="`tag is-medium is-itside-${cellData}`"
              >{{ $t(`einvoice.status.options.${cellData}`) }}</span
            > -->
          <span v-if="column.field === 'status'" class="has-text-weight-bold">{{
            $t(`einvoice.status.options.${cellData}`)
          }}</span>
          <span v-else-if="column.field === '_einv_color'">
            <b-icon
              icon="circle"
              size="is-large"
              :type="`is-itside-${row['status']}`"
            ></b-icon>
          </span>
          <template v-else>{{ cellData }}</template>
        </template>
      </document-table>
    </section>
  </section>
</template>

<script>
import { searchPageMixin, uaMixin } from "@/mixins";
import {
  einvoiceService,
  downloadService,
  fetchInvoicesResponseSchema,
} from "@/services";
import {
  Operators,
  KEInvoiceFilters,
  getEInvoiceDownloadType,
  KValueTypes,
  checkType,
} from "@/helpers/constants";
// import { deepCopy } from "@/helpers";
import CustomBDatePicker from "@/components/inputs/CustomBDatePicker.vue";
import DocumentTable from "@/components/DocumentTable/DocumentTable";
import TableColumnFilter from "@/components/DocumentTable/TableColumnFilter";
// import { mapState, mapActions, mapGetters } from "vuex";
import { mapGetters } from "vuex";
import BooleanInput from '../components/inputs/BooleanInput.vue';

export default {
  components: {
    CustomBDatePicker,
    DocumentTable,
    TableColumnFilter,
    BooleanInput,
  },
  mixins: [searchPageMixin, uaMixin],
  data() {
    return {
      isCreditClass: undefined,
      isLoadingInvoices: false,
      isLoadingFilters: false,
      isDownloading: false,
      invoicesLoaded: false,
      invoiceData: [],
      tablePage: 1,
      tablePerPage: 10,
      totalDocumentNum: 0,
      totalTablePages: 0,
      tableSortingPriority: [],
      filters: [],
      valueTypes: KValueTypes,
      operators: Operators,
      downloadTypes: [],
      dateRange: [],
      einvoiceTabs: [],
      activeTab: 0,
      charasd: "&#F0110;",
      einvStatus: "",
      einvStatusOptions: [
        {
          label: this.$t("einvoice.status.options.OK"),
          value: "OK",
          color: "00FF00",
        },
        {
          label: this.$t("einvoice.status.options.WAITING"),
          value: "WAITING",
          color: "FFFF00",
        },
        {
          label: this.$t("einvoice.status.options.EXPIRING"),
          value: "EXPIRING",
          color: "FFA500",
        },
        {
          label: this.$t("einvoice.status.options.WARNING"),
          value: "WARNING",
          color: "E500FF",
        },
        {
          label: this.$t("einvoice.status.options.ERROR"),
          value: "ERROR",
          color: "FF0000",
        },
        {
          label: this.$t("einvoice.status.options.SOLVED"),
          value: "SOLVED",
          color: "4F92FF",
        },
        {
          label: this.$t("einvoice.status.options.VIM"),
          value: "VIM",
          color: "AAAAAA",
        },
      ],
      currStatus: "",
      currStatusBgColor: "",
      // visibleFilters: [],
    };
  },
  computed: {
    ...mapGetters({
      getClassByName: "menu/getClassByName",
    }),
    className: function () {
      return this.$route.query.class_name;
    },
    companyName: function () {
      return this.$route.params.company;
    },
    isLoading: function () {
      return (
        this.isLoadingInvoices || this.isDownloading || this.isLoadingFilters
      );
    },
    invoiceTableColumns: function () {
      const einv = this.invoiceData[0];
      if (einv) {
        const columns = Object.keys(einv)
          .filter((prop) => prop !== "button")
          .map((prop) => {
            const obj = {
              label: this.$t(`einvoice.columns.${prop}`),
              field: prop,
              sortable: true,
              cellClass: null,
            };
            if (obj.field === "status") {
              obj.sortable = false;
              obj.cellClass = "v-align-middle";
            } else if (obj.field === "statusDescription") {
              obj.sortable = false;
            }
            return obj;
          });
        columns.push({
          label: this.$t("einvoice.status.color.label"),
          field: "_einv_color",
          centered: true,
          sortable: false,
          headerClass: "width-fit",
          cellClass: "v-align-middle",
        });
        return columns;
      } else {
        return [];
      }
    },
    stateKey: function () {
      return `${this.companyName}.${this.className}.einv_search`;
    },
  },
  watch: {
    currStatus: function (newValue) {
      this.einvStatus = newValue.value;
    },
    // visibleFiltersNames: function (newVal) {
    //   console.log("changed visibleFiltersNames:", newVal);
    //   this.visibleFilters = this.filters.filter((f) => f.visible);
    // },
  },
  methods: {
    /**
     * Close a tab
     */
    closeTab: function (tabIndex) {
      if (this.activeTab === tabIndex + 1) {
        this.activeTab = 0;
      }
      this.einvoiceTabs.splice(tabIndex, 1);
    },
    computeTableCellClass: function (cellKey, cellData) {
      let styleClass = "";
      if (cellKey && cellKey === "status") {
        styleClass = `p-tag itside-tag-${cellData}`;
      }
      return styleClass;
    },
    downloadEInvoices: function (payload) {
      const artifacts = payload.artifacts;
      const invoices = payload.selected;
      this.isDownloading = true;
      const ids = [];
      for (const inv of invoices) {
        ids.push(inv.invoiceId);
      }
      downloadService
        .downloadEInvoices(
          this.$route.params.company,
          this.$i18n.locale,
          "ddMMyyyy",
          true,
          ids,
          artifacts,
          this.isCreditClass
        )
        .then((data) => {
          console.log(data);
        })
        .finally(() => (this.isDownloading = false));
    },
    downloadTotalEInvoices(payload) {
      const artifacts = payload.artifacts;
      this.isDownloading = true;
      const einvoiceFilters = this.filters.filter((f) => {
        if (f.value || f.value === false) {
          if (Array.isArray(f.value)) {
            if (f.value.length > 0) {
              // Array not empty
              return true;
            } else {
              // Array empty
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      });
      einvoiceFilters.push({
        name: "CLASS_NAME",
        type: "STRING",
        value: this.className,
        operator: "E",
      });
      // const filtersDeepCopy = deepCopy(einvoiceFilters);
      const statusFilter = this.einvStatus ? this.einvStatus.value : null;
      downloadService
        .downloadAllEInvoices(
          this.className,
          statusFilter,
          // filtersDeepCopy,
          einvoiceFilters,
          this.$route.params.company,
          this.$i18n.locale,
          "ddMMyyyy",
          true,
          artifacts,
          this.isCreditClass
        )
        .then()
        .finally(() => (this.isDownloading = false));
    },
    fetchInvoices() {
      const einvoiceFilters = this.filters.filter((f) => {
        if (f.value || f.value === false) {
          if (Array.isArray(f.value)) {
            if (f.value.length > 0) {
              // Array not empty
              return true;
            } else {
              // Array empty
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      });
      einvoiceFilters.push({
        name: "CLASS_NAME",
        type: "STRING",
        value: this.className,
        operator: "E",
      });

      // const filtersDeepCopy = deepCopy(einvoiceFilters);
      this.isLoadingInvoices = true;
      const tableSorting = {};
      this.tableSortingPriority.forEach((el) => {
        tableSorting[el.field] = el.order;
      });
      const company = this.companyName;
      const className = this.className;
      const page = this.tablePage;
      const perPage = this.tablePerPage;
      const statusFilter = this.einvStatus ? this.einvStatus.value : null;
      einvoiceService
        .fetchClassInvoices(
          company,
          className,
          statusFilter,
          // filtersDeepCopy,
          einvoiceFilters,
          page,
          perPage,
          tableSorting,
          this.isCreditClass
        )
        .then((invoices) => {
          if (invoices[fetchInvoicesResponseSchema.einv])
            this.invoiceData = invoices[fetchInvoicesResponseSchema.einv];
          this.totalDocumentNum =
            invoices[fetchInvoicesResponseSchema.totalResults];
          this.tablePage = invoices[fetchInvoicesResponseSchema.page];
          this.totalTablePages =
            invoices[fetchInvoicesResponseSchema.totalPages];
        })
        .finally(() => (this.isLoadingInvoices = false));
    },
    openEinvoiceDetail(payload) {
      /*
       * If the selected document is already displayed switch to that tab
       * otherwise add a new tab
       */
      // for (let i = 0; i < this.einvoiceTabs.length; i++) {
      //   if (this.einvoiceTabs[i].invoiceId === payload.invoiceId) {
      //     this.activeTab = i + 1; // Add 1 because 0 is home tab
      //     return;
      //   }
      // }
      // this.einvoiceTabs.push(payload);
      // setTimeout(() => (this.activeTab = this.einvoiceTabs.length), 1000);

      const tab = {
        id: payload.invoiceId,
        component: "EInvoiceDetail",
        label: `Einv ${payload.invoiceId}`,
        icon: this.$config.icons.tabs.einvoice_detail,
        props: {
          companySchema: this.companyName,
          className: this.className,
          einvoice: { ...payload },
          stateKey: `${this.companyName}.${this.className}`,
          isCredit: this.isCreditClass,
          properties: [...this.invoiceTableColumns],
        },
      };
      this.$store.dispatch("tabs/openTab", tab);
    },
    onTablePage: function (event) {
      this.tablePage = event.page;
      this.tablePerPage = event.rows;
      this.fetchInvoices();
    },
    onTableSort(sortingPriority) {
      this.tableSortingPriority = sortingPriority;
      this.fetchInvoices();
    },
    resetForm() {
      for (const filter of this.filters) {
        filter.value = null;
      }
    },
    ...checkType,
  },
  mounted() {
    try {
      this.isLoadingFilters = true;
      let fi = [];
      if (
        this.getClassByName(this.className, this.companyName).isCreditInvoice
      ) {
        this.isCreditClass = true;
        fi = KEInvoiceFilters.getActiveEInvoiceFilters();
      } else if (
        this.getClassByName(this.className, this.companyName).isDebitInvoice
      ) {
        this.isCreditClass = false;
        fi = KEInvoiceFilters.getPassiveEInvoiceFilters();
      } else {
        this.isCreditClass = undefined;
        fi = KEInvoiceFilters.getAllEInvoiceFilters();
      }
      if (this.$config.search.auto_year_filter) {
        let year = fi.find((f) => f.name.toLowerCase() === "fiscal_year");
        console.log(year);
        if (year) year.value = new Date().getUTCFullYear();
      }
      this.filters = fi;
    } catch (error) {
      this.isCreditClass = undefined;
      this.filters = KEInvoiceFilters.getAllEInvoiceFilters();
    } finally {
      this.filters.forEach((f) => {
        f.label = this.$t(`einvoice.filters.${f.name}`);
        // f.visible = true;
        this.$set(f, "visible", true);
      });
      this.loadFiltersState();
      this.isLoadingFilters = false;
    }

    this.downloadTypes = getEInvoiceDownloadType();
  },
};
</script>
<style lang="scss" scoped>
.custom-center-content {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: flex-end; */
}
.loading-dashboard {
  left: $sidebar-width;
}
</style>
